<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-app-bar
        dark
        :height="appBarHeight"
      >
        <v-btn icon @click="dialog = !dialog"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>{{ ! editable ? $t('detail') : isNew ? $t('new') : $t('edit') }}</v-toolbar-title>
      </v-app-bar>
      <v-list>
        <!-- 選択 -->
        <v-list-item class="select" v-show="editable">
          <v-list-item-content>{{ $t('method') }}</v-list-item-content>
          <v-list-item-content>
            <v-radio-group
              v-model="bottleType"
              row
              mandatory
            >
              <v-radio
                :label="$t('byNumber')"
                value="num"
              ></v-radio>
              <v-radio
                :label="$t('byRemain')"
                value="remain"
              ></v-radio>
            </v-radio-group>
          </v-list-item-content>
        </v-list-item>
        <!-- 銘柄 -->
        <v-list-item class="brand">
          <v-list-item-avatar><v-icon>mdi-bottle-wine</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-combobox
              v-model="bottle.brand"
              :items="_.map(brands, 'label')"
              :label="$t('brand')"
              :readonly="! editable"
            ></v-combobox>
          </v-list-item-content>
        </v-list-item>
        <!-- 番号 -->
        <v-list-item class="num" v-show="bottleType === 'num'">
          <v-list-item-avatar><v-icon>mdi-label</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-text-field
              v-model="bottle.num"
              type="number"
              :label="$t('number')"
              :readonly="! editable"
            ></v-text-field>
          </v-list-item-content>
          <v-list-item-action v-if="editable">
            <v-btn
              fab
              small
              class="option"
              v-for="num in numOptions"
              :key="num"
              color="#666"
              @click="bottle.num = num"
            >{{ num }}</v-btn>
          </v-list-item-action>
        </v-list-item>
        <!-- 残量 -->
        <v-list-item class="remain" v-show="bottleType === 'remain'">
          <v-list-item-avatar><v-icon>mdi-battery-70</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-slider
              v-model="bottle.remain"
              step="20"
              :label="$t('remain')"
            ></v-slider>
          </v-list-item-content>
          <v-list-item-action>{{ bottle.remain }}<span class="unit">%</span></v-list-item-action>
        </v-list-item>
        <!-- 名前 -->
        <v-list-item class="names">
          <v-list-item-avatar><v-icon>mdi-account-multiple</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-text-field
              v-for="(name, index) in bottle.names"
              :key="index"
              v-model="bottle.names[index]"
              :append-icon="index > 0 ? 'mdi-close' : ''"
              @click:append="removeNamesMemos('names', index)"
              :label="$t('name')"
              :readonly="! editable"
            ></v-text-field>
          </v-list-item-content>
          <v-list-item-action v-if="editable">
            <v-btn
              icon
              @click="bottle.names.push('')"
            ><v-icon>mdi-plus-box</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <!-- メモ -->
        <v-list-item class="memos">
          <v-list-item-avatar><v-icon>mdi-message-reply</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-text-field
              v-for="(memo, index) in bottle.memos"
              :key="index"
              v-model="bottle.memos[index]"
              :append-icon="editable ? 'mdi-close' : ''"
              @click:append="removeNamesMemos('memos', index)"
              :label="$t('note')"
              :readonly="! editable"
            ></v-text-field>
          </v-list-item-content>
          <v-list-item-action v-if="editable">
            <v-btn
              icon
              @click="bottle.memos.push('')"
            ><v-icon>mdi-plus-box</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <!-- 開封日 -->
        <v-list-item class="date">
          <v-list-item-avatar><v-icon>mdi-calendar</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-text-field
              v-model="bottle.createdAt"
              :label="$t('openedAt')"
              type="date"
              :readonly="! editable"
            ></v-text-field>
          </v-list-item-content>
          <v-list-item-action v-if="editable">
            <v-btn
              icon
              @click="bottle.createdAt = dayjs().format('YYYY-MM-DD')"
            ><v-icon>mdi-autorenew</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <!-- 来店日 -->
        <v-list-item class="date">
          <v-list-item-avatar><v-icon>mdi-calendar</v-icon></v-list-item-avatar>
          <v-list-item-content>
            <v-text-field
              v-model="bottle.updatedAt"
              :label="$t('updatedAt')"
              type="date"
              :readonly="! editable"
            ></v-text-field>
          </v-list-item-content>
          <v-list-item-action v-if="editable">
            <v-btn
              icon
              @click="bottle.updatedAt = dayjs().format('YYYY-MM-DD')"
            ><v-icon>mdi-autorenew</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card-actions v-if="editable">
        <v-btn
          v-if="! isNew"
          icon
          color="warning"
          @click="remove()"
        ><v-icon>mdi-delete</v-icon></v-btn>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >{{ $t('dialog.no') }}</v-btn>
        <v-btn
          color="primaryBG"
          large
          @click="save()"
        >{{ $t('dialog.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        bottle: {},
        isNew: false,
        bottleType: 'num', // 'num' or 'remain'
        errorDialog: false,
      }
    },

    computed: {
      dialog: {
        get() { return ! _.isNull(this.bottleIndex) },
        set() { this.bottleIndex = null }
      },
      numOptions() {
        let numOptions = _.range(0, 1000)
        const usedNums = _.map(_.filter(this.bottles, bottle => { return bottle.brand === this.bottle.brand }), 'num')
        _.pullAt(numOptions, _.concat([0], usedNums))
        return usedNums.length ? _.take(numOptions, 4) : []
      }
    },

    watch: {
      bottleIndex() {
        const bottle = _.find(this.bottles, bottle => { return bottle.index === this.bottleIndex })
        if (bottle) {
          // 編集
          this.isNew = false
          this.bottle = _.cloneDeep(bottle)
          this.bottleType = (_.isNull(this.bottle.num) || ! _.isNull(this.bottle.remain)) ? 'remain' : 'num'
        } else {
          // 新規
          this.isNew = true
          this.bottle = {
            id: _.maxBy(this.bottles, 'id') ? _.maxBy(this.bottles, 'id').id + 1 : 1,
            createdAt: dayjs().format('YYYY-MM-DD'),
            updatedAt: dayjs().format('YYYY-MM-DD'),
            brand: null,
            num: 0,
            names: [''],
            memos: [''],
          }
          this.bottleType = 'num'
        }
      }
    },

    async mounted() {},

    methods: {
      async remove() {
        this.isLoading = true
        await this.deleteRow(this.bottle.index)
        await this.renewData()
        this.isLoading = false
        this.bottleIndex = null
      },
      async save() {
        this.isLoading = true
        this.bottle.num = (this.bottleType === 'num') ? this.bottle.num : ''
        this.bottle.remain = (this.bottleType === 'remain') ? this.bottle.remain : ''
        let index = this.isNew ? this.bottles.length + this.firstRowIndex + 1 : this.bottle.index
        await this.saveRow(index, this.bottle)
        await this.renewData()
        this.isLoading = false
        this.bottleIndex = null
      },
      removeNamesMemos(type, index) {
        _.pullAt(this.bottle[type], [index])
        this.bottle = _.cloneDeep(this.bottle)
      },
    },
    i18n: {
      messages: {
        ja: {
          edit: '編集',
          new: '新規追加',
          detail: '詳細',
          method: 'ボトルの管理方法',
          byNumber: '番号',
          byRemain: '残量',
          openedAt: '開封日',
          updatedAt: '来店日',
        },
        en: {
          edit: 'Edit',
          new: 'Add New',
          detail: 'Detail',
          method: 'Bottle Management',
          byNumber: 'Num',
          byRemain: 'Remain',
          openedAt: 'Opened Date',
          updatedAt: 'Visited Date',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-list {
    padding: 0;
  }
  .v-list-item {
    padding: 0 5vw;
  }
  .v-list-item__content {
    padding: 1vh 0;
    overflow: visible;
  }
  .v-input {
    font-size: 1.2rem;
    ::v-deep .v-input__slot {
      margin: 0;
    }
    ::v-deep .v-messages {
      display: none;
    }
  }
  .select {
    background-color: var(--v-primaryBG-base);
    padding: 10px;
    justify-content: space-between;
    .v-list-item__content {
      flex: none;
    }
    .v-input--selection-controls {
      margin: 0;
      padding: 0;
    }
    ::v-deep .v-label {
      font-size: 1.2rem;
    }
  }
  .num {
    .v-list-item__action {
      flex-direction: row;
      align-items: center;
      .v-btn {
        margin-left: 5px;
        font-size: 1.1rem;
      }
    }
  }
  .remain {
    .v-input {
      padding: 20px 0 10px 0;
    }
    .v-list-item__action {
      font-size: 2rem;
      flex-wrap: nowrap;
      flex-direction: row;
      .unit {
        font-size: .5em;
        margin-left: 2px;
        position: relative;
        top: -6px;
      }
    }
    ::v-deep .v-label {
      position: absolute !important;
      top: -15px !important;
      left: 0 !important;
      font-size: 1rem;
    }
  }
  .names,
  .memos {
    .v-list-item__title {
      display: flex;
      flex-wrap: wrap;
    }
    .v-input {
      margin: 0 10px 0 0;
    }
  }
  .v-card__actions {
    padding: 8px 24px !important;
    .v-size--large {
      padding: 0 30px;
    }
  }
</style>
