<template>
  <fragment>

    <TheAppbar :title="$t('title')" />

    <v-main>
      <v-container>
        <div class="privacy" v-html="privacy"></div>
      </v-container>
    </v-main>

  </fragment>
</template>

<script>
  import INIT from '../../init.js'
  import TheAppbar from './TheAppbar.vue';

  export default {
    data() {
      return {
        privacy: '',
      };
    },
    async mounted() {
      const res = await axios.get(INIT.URLS.privacy).catch((error) => { console.log(error) })
      if (_.has(res, 'data.content.rendered')) {
        this.privacy = res.data.content.rendered
      }
    },
    i18n: {
      messages: {
        ja: {
          title: 'プライバシーポリシー',
        },
        en: {
          title: 'Privacy Policy',
        }
      }
    },
    components: { TheAppbar },
  }
</script>

<style scoped>
  .container {
    max-width: 800px;
    padding: 20px;
  }
  .privacy>>>h2 {
    font-size: 1.2rem;
    margin: 50px 0 20px;
  }
  .privacy>>>li {
    margin-bottom: 10px;
  }
</style>
