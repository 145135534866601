// global
import 'lodash'
import dayjs from 'dayjs'
import axios from 'axios'
window.dayjs = dayjs
window.axios = axios

// import
import Vue from 'vue'
import App from './App.vue'
import router from './settings/router'
import store from './settings/store'
import vuetify from './settings/vuetify'
import i18n from './settings/i18n'
import './settings/mixin'
import './registerServiceWorker'

// plugins
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'

// fragment（enable multi root component）
// vue-fragment@1.5.1
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  created() {
    // google login status
    GoogleAuth.init()
  },
  async mounted() {
    // admob
    // this.admobShow()
  },
  watch: {}
}).$mount('#app')

Vue.config.productionTip = false
