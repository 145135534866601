<template>
  <fragment>
    
    <TheAppbar :title="$t('title')" />
    <AddButton />
    
    <v-main v-show="isReady">
      <SignIn />
      <Search />
      <Lists />
    </v-main>
    
    <DetailDialog />
    <ErrorDialog />

  </fragment>
</template>

<script>
  import TheAppbar from './HomeAppbar.vue'
  import AddButton from './HomeAdd.vue'
  import SignIn from './HomeSignIn.vue'
  import Lists from './HomeLists.vue'
  import Search from './HomeSearch.vue'
  import DetailDialog from './DetailDialog.vue'
  import ErrorDialog from './ErrorDialog.vue'

  export default {
    data() {
      return {}
    },
    computed: {},
    async mounted() {
      this.isReady = false
      if (! this.bottles) {
        if (this.appData.spreadsheetId) {
          if (this.appData.refreshToken) {
            this.isLoading = true
            await this.renewData()
            await this.checkEditable()
            this.isLoading = false
          }
        }
      }
      this.isReady = true
    },
    watch: {},
    methods: {},
    i18n: {
      messages: {
        ja: {
          title: 'BOTTLES',
        },
        en: {
          title: 'BOTTLES',
        }
      }
    },
    components: { TheAppbar, AddButton, SignIn, Lists, Search, DetailDialog, ErrorDialog },

  };
</script>

<style lang="scss" scoped>
</style>
