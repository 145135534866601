
import INIT from '../init.js'
import axios from 'axios'

export default {
  methods: {
    // 新規作成
    async apiCreate(newSheets) {
      const accessToken = await this.getAccessToken()
      if (accessToken) {
        const res = await axios.post('https://sheets.googleapis.com/v4/spreadsheets', {
          properties: {title: this.isJa ? 'ボトル管理帳' : 'Bottles'},
          sheets: newSheets
        }, {params: {access_token: accessToken}}).catch((err) => { console.log('create:', err, err.response) })
        return res || null
      } else {
        return null
      }
    },
    // 新規データ取得
    async apiGetNewSheets() {
      const sheetId = this.isJa ? '1FKt3V73GhZRo9MUNNsuaGOy22i9_Wy4HZnWpVQJk_Rg' : '1llq0WQjWysvP53FoXqsQIDzNAtkk4fkedN1-qYNBxro'
      const res = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + sheetId, {
        params: {
          includeGridData: true,
          ranges: 'A1:M20',
          key: 'AIzaSyC5LybZUI5nwe37qzCFaZwvdBELpF9WcV8'
        }
      }).catch((err) => { console.log('get new sheet:', err) })
      return res || null
    },
    // セルデータ更新
    async apiUpdateCell(cell, value) {
      const accessToken = await this.getAccessToken()
      if (accessToken) {
        const res = await axios.post('https://sheets.googleapis.com/v4/spreadsheets/' + this.appData.spreadsheetId + '/values:batchUpdate', {
          valueInputOption: 'RAW',
          data: [{
            range: cell,
            values: [[value]]
          }]
        }, {params: {access_token: accessToken}}).catch((err) => { console.log('save: ', err, err.response) })
        return res || null
      } else {
        return null
      }
    },
    // 行データ更新
    async apiUpdateRow(index, values) {
      const accessToken = await this.getAccessToken()
      if (accessToken) {
        const res = await axios.post('https://sheets.googleapis.com/v4/spreadsheets/' + this.appData.spreadsheetId + '/values:batchUpdate', {
          valueInputOption: 'RAW',
          data: [{
            range: 'A' + index,
            values: values
          }]
        }, {params: {access_token: accessToken}}).catch((err) => { console.log('save: ', err, err.response) })
        return res || null
      } else {
        return null
      }
    },
    // データ削除
    async apiDeleteRow(index) {
      const accessToken = await this.getAccessToken()
      if (accessToken) {
        const res = await axios.post('https://sheets.googleapis.com/v4/spreadsheets/' + this.appData.spreadsheetId + ':batchUpdate', {
          requests: [{deleteDimension: {
            range: {
              sheetId: 0,
              dimension: 'ROWS',
              startIndex: index - 1,
              endIndex: index
            },
          }}]
        }, {params: {access_token: accessToken}}).catch((err) => { console.log('delete: ', err, err.response) })
        return res || null
      } else {
        return null
      }
    },
    // 編集権限チェック
    async apiCheckEditable() {
      let error
      const accessToken = await this.getAccessToken()
      if (accessToken) {
        await axios.post('https://sheets.googleapis.com/v4/spreadsheets/' + this.appData.spreadsheetId + ':batchUpdate', {
          requests: [{createDeveloperMetadata: {developerMetadata: {}}}]
        }, {params: {access_token: accessToken}}).catch((err) => {
          error = err
        })
        return error
      } else {
        return null
      }
    },
    // データ更新
    async apiGetData() {
      const accessToken = await this.getAccessToken()
      if (accessToken) {
        let error
        const res = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + this.appData.spreadsheetId + '/values/' + INIT.DATA_STRUCTURE.range, {params: {access_token: accessToken}}).catch((err) => { error = err.response })
        return error || res
      } else {
        return null
      }
    },
  }
}

