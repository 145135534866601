<template>
  <v-app-bar
    app
    dark
    :color="(keyword0 || keyword1 || keyword2 || isSearching) ? '#111' : ''"
    :height="appBarHeight + 22"
  >
    <v-app-bar-nav-icon
      v-if="! isLargeScreen && ! isSearching && ! keyword0 && ! keyword1 && ! keyword2"
      text
      @click="toggleDrawer"
    ><v-icon>mdi-menu</v-icon></v-app-bar-nav-icon>
    <v-app-bar-nav-icon
      v-else-if="isSearching || keyword0 || keyword1 || keyword2"
      text
      @click="clearSearch"
    ><v-icon>mdi-arrow-left</v-icon></v-app-bar-nav-icon>
    <span
      v-show="keyword0"
      class="bottle-type"
      @click="isSearching = true"
    >{{ keyword0 === 'num' ? '番号' : '残量' }}</span>
    <span
      v-show="keyword1"
      class="brand"
      @click="isSearching = true"
    >{{ keyword1 }}</span>
    <v-text-field
      v-if="user && bottles"
      ref="search"
      v-model="keyword2"
      solo
      :label="$t('searchHere')"
      filled
      clearable
      @keyup.enter="enter"
      @focus="isSearching = true"
    ></v-text-field>
    <v-app-bar-title
      v-else
    >BOTTLES</v-app-bar-title>
  </v-app-bar>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    methods: {
      toggleDrawer() {
        this.$store.commit('setDrawer', ! this.$store.state.drawer)
      },
      clearSearch() {
        this.keyword0 = null
        this.keyword1 = null
        this.keyword2 = null
        this.isSearching = false
      },
      enter() {
        this.$refs.search.blur()
        this.isSearching = false
      }
    },
    i18n: {
      messages: {
        ja: {
          searchHere: 'ここで検索できます',
        },
        en: {
          searchHere: 'Search here',
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .v-app-bar {
    ::v-deep .v-toolbar__content {
      padding-top: calc(env(safe-area-inset-top) * 0.75);
      padding-bottom: 0;
    }
    ::v-deep .v-text-field__details {
      display: none;
    }
  }
  .v-btn {
    margin: 0 10px 0 -5px !important;
  }
  .bottle-type,
  .brand {
    background-color: rgba(255,255,255,.2);
    border-radius: 20px;
    line-height: 1.1;
    font-size: .8rem;
    padding: 5px 10px;
    opacity: .8;
    margin: 0 8px 0 0;
    text-align: center;
    min-height: 36px;
    min-width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 40%;
  }
  .v-input {
    ::v-deep .v-input__slot {
      margin: 0;
      box-shadow: none !important;
      input {
        font-size: 1.2rem;
      }
    }
  }
</style>
