import Vue from 'vue'
import VueRouter from 'vue-router'
import _router from '../commons/_router'
import Home from '../components/Home.vue'
import Account from '../components/Account.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: _.concat(_router, [{
    path: '/account',
    name: 'account',
    component: Account
  }, {
    // 外部Data読み込み用（必ず最後に読み込む）
    path: '/*',
    name: 'home',
    component: Home
  }])
})

export default router
