<template>
  <v-btn
    v-if="bottles && editable && ! isSearching && ! keyword1 && ! keyword2"
    color="#455A64"
    fab
    dark
    fixed
    bottom
    right
    @click="add()"
  ><v-icon>mdi-plus</v-icon></v-btn>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    watch: {},
    methods: {
      add() {
        this.bottleIndex = '-'
      }
    },
  };
</script>

<style lang="scss" scoped>
  .v-btn {
    bottom: 30px;
    z-index: 5;
  }
</style>
