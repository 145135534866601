<template>
  <fragment>
    <TheAppbar :title="$t('title')" />
    <v-main>
      <v-card>
        <v-card-title>{{ $t('status') }}</v-card-title>
        <v-list three-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('account') }}</v-list-item-title>
              <v-list-item-subtitle>{{ _.get(user, 'email') || '-' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('data') }}</v-list-item-title>
              <v-list-item-subtitle
                ref="spreadsheetId"
              >{{ appData.spreadsheetId && user ? appData.spreadsheetId : '-' }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="appData.spreadsheetId && user">
              <v-btn
                icon
                @click="copy()"
              ><v-icon>mdi-content-copy</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-text v-if="user">
          <v-btn
            color="primaryBG"
            block
            @click="signOutSequence()"
          >{{ $t('logout') }}</v-btn>
        </v-card-text>
      </v-card>
    </v-main>
  </fragment>
</template>

<script>
  import TheAppbar from '../commons/components/TheAppbar.vue'
  export default {
    data() {
      return {}
    },
    computed: {},
    async mounted() {},
    methods: {
      copy() {
        const text = this.$refs.spreadsheetId.innerText
        navigator.clipboard.writeText(text);
      },
      async signOutSequence() {
        this.isLoading = true
        setTimeout(async () => {
          await this.signOut()
          this.isLoading = false
        }, 1000)
      },
    },
    i18n: {
      messages: {
        ja: {
          title: 'アカウントとデータ',
          status: '現在のログイン状況',
          account: 'アカウント',
          data: 'データ',
          logout: 'ログアウト'
        },
        en: {
          title: 'Account & Data',
          status: 'Login status',
          account: 'Account',
          data: 'Data',
          logout: 'Logout'
        }
      }
    },
    components: { TheAppbar },
  };
</script>

<style lang="scss" scoped>
  .v-list-item__action {
    .v-btn {
      margin-top: 15px;
    }
  }
</style>
