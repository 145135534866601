import Vue from 'vue'
import Vuex from 'vuex'
import _store from '../commons/_store'
import INIT from '../init.js'

Vue.use(Vuex)

const mutations = {}
_.forEach(INIT.STORE_STATE, (init, key) => {
  mutations['set' + _.capitalize(key)] = (state, value) => {
    state[key] = value
  }
})

export default new Vuex.Store({
  strict: true,
  namespaced: true,
  state: Object.assign(_store.state, INIT.STORE_STATE),
  mutations: Object.assign(_store.mutations, mutations),
})
