<template>
  <v-app id="app">
    <AppInitialize />
    <AppDrawer />
    <!-- vue-fragment@1.5.1固定 -->
    <router-view />
    <AppNetwork />
    <AppLoading />
  </v-app>
</template>

<script>
  import AppInitialize from './commons/components/AppInitialize.vue'
  import AppDrawer from './commons/components/AppDrawer.vue';
  import AppNetwork from './commons/components/AppNetwork.vue';
  import AppLoading from './commons/components/AppLoading.vue';

  export default {
    async mounted() {},
    components: { AppInitialize, AppDrawer, AppNetwork, AppLoading },
  }
</script>

<style>
  .v-navigation-drawer .v-footer .language {
    display: none !important;
  }
</style>

<style lang="scss">
  // 大画面時のダイアログサイズ制限
  .v-dialog:not(.v-dialog--fullscreen) {
    max-width: 400px !important;
  }
  // 全画面ダイアログの角丸
  .v-dialog--fullscreen > .v-card {
    border-radius: 0 !important;
  }
</style>
