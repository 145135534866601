<template>
  <fragment>
    
    <v-card
      class="login"
      v-if="! user || ! appData.spreadsheetId"
    >
      <v-card-text>{{ $t('descSignIn1') }}<br>{{ $t('descSignIn2') }}</v-card-text>
      <v-radio-group v-model="loginType">
        <v-radio
          :label="$t('signInNew')"
          value="new"
        ></v-radio>
        <v-radio
          :label="$t('signInRestore')"
          value="restore"
        ></v-radio>
      </v-radio-group>

      <!-- 新規 -->
      <v-card-text v-show="loginType === 'new'">
        <v-form
          ref="formNew"
          v-model="validNew"
          @submit.prevent
        >
          <v-text-field
            v-model="storeName"
            :label="$t('storeName')"
            required
            clearable
            @keyup.enter="firstSignIn"
            :rules="[() => !!storeName || $t('insertStoreName')]"
          ></v-text-field>
          <v-btn
            text
            @click="firstSignIn"
          ><img :src="require('../assets/google_signin_dark.png')"></v-btn>
        </v-form>
      </v-card-text>

      <!-- リストア -->
      <v-card-text v-show="loginType === 'restore'">
        <v-form
          ref="formRestore"
          v-model="validRestore"
          @submit.prevent
        >
          <v-combobox
            v-model="selectedId"
            :items="idList"
            :label="$t('spreadSheetID')"
            required
            clearable
            :rules="[() => !!selectedId || $t('insertID')]"
            @keyup.enter="restoreSignIn"
          ></v-combobox>
          <v-btn
            text
            @click="restoreSignIn"
          ><img :src="require('../assets/google_signin_dark.png')"></v-btn>
        </v-form>
      </v-card-text>
    </v-card>
      
  </fragment>
</template>

<script>
  import INIT from '../init.js'

  export default {
    data() {
      return {
        validRestore: true,
        validNew: true,
        loginType: 'new', // 'new', 'restore'
        newSheets: null,
        storeName: '',
        selectedId: '',
      }
    },
    computed: {
      idList(){ return _.map(this.appData.sheets, (sheet) => { return sheet.storeName + ': ' + sheet.id.substr(0, 10) + '...'})}
    },
    async mounted() {
      // 初期選択ID
      const sheet = _.find(this.appData.sheets, ['id', this.appData.spreadsheetId])
      this.selectedId = sheet ? sheet.storeName + ': ' + sheet.id.substr(0, 10) + '...' : null
      // loginType初期値
      this.loginType = this.appData.spreadsheetId ? 'restore' : 'new'
      // 事前読み込み
      if (! this.user) {
        this.newSheets = await this.getNewSheets()
      }
    },
    watch: {},
    methods: {
      // 新規
      async firstSignIn() {
        this.$refs.formNew.validate()
        if (this.validNew) {
          const res = await this.signIn()
          if (res) {
            this.isLoading = true
            await this.create(this.newSheets)
            await this.apiUpdateCell(INIT.DATA_STRUCTURE.rangeStore, this.storeName)
            await this.renewData()
            this.editable = true
            this.isLoading = false
          }
        }
      },
      // 既存
      async restoreSignIn() {
        this.$refs.formRestore.validate()
        if (this.validRestore) {
          if (_.includes(this.selectedId, '...')) {
            const pickedId = this.selectedId.replace('...', '').substr(-10)
            this.selectedId = _.find(this.appData.sheets, (sheet) => { return _.includes(sheet.id, pickedId) }).id
          }
          this.isLoading = true
          this.appData = _.assign(this.appData, {spreadsheetId: this.selectedId})
          const res = await this.signIn()
          if (res) {
            await this.renewData()
            await this.checkEditable()
          }
          // setTimeout(() => {
          // }, 10000)
          this.isLoading = false
        }
      },
      // ログアウト
      async signOutSequence() {
        this.isLoading = true
        await this.signOut()
        this.isLoading = false
      },
    },
    i18n: {
      messages: {
        ja: {
          signInNew: '新規で始める',
          signInRestore: 'データを読み込んで始める',
          spreadSheetID: 'スプレッドシートID',
          descSignIn1: 'このアプリは、「Google スプレッドシート」にデータを保存します。',
          descSignIn2: 'Googleアカウントへのログインをお願いします。',
          storeName: '店舗名',
          insertStoreName: '店舗名を入力してください',
          insertID: 'スプレッドシートIDを入力して下さい',
          logout: 'ログアウト',
        },
        en: {
          signInNew: 'Login and create new data',
          signInRestore: 'Login and restore data',
          spreadSheetID: 'Spreadsheet ID',
          descSignIn1: 'This app saves data in "Google Spreadsheets".',
          descSignIn2: 'Please log in to your Google account.',
          storeName: 'Store name',
          insertStoreName: 'Please enter store name',
          insertID: 'Please enter your spreadsheet ID',
          logout: 'LOGOUT',
        }
      }
    },

  }
</script>

<style lang="scss" scoped>
  .error-message {
    color: var(--v-error-base) !important;
  }
  .v-card {
    background-color: transparent;
    box-shadow: none !important;
  }
  .v-input--radio-group {
    margin: 16px 30px 0;
    .v-radio {
      padding: 15px 0;
    }
  }
  .login {
    .v-form {
      max-width: 300px;
      margin: 0 auto;
    }
    .v-btn {
      margin-top: 20px;
      height: auto !important;
      img {
        width: 80%;
        max-width: 300px;
      }
    }
  }
</style>
