// common router
// lazy-loadを使うとvuetifyでエラー
import Home from '../components/Home.vue'
import Contact from '../commons/components/Contact.vue'
import Setting from '../components/Setting.vue'
import Request from '../commons/components/Request.vue'
import Privacy from '../commons/components/Privacy.vue'

export default [{
  path: '/',
  name: 'home',
  component: Home
}, {
  path: '/setting',
  name: 'setting',
  component: Setting
}, {
  path: '/contact',
  name: 'contact',
  component: Contact
}, {
  path: '/request',
  name: 'request',
  component: Request
}, {
  path: '/privacy',
  name: 'privacy',
  component: Privacy
}, {
  path: '/en/',
  name: 'home-en',
  component: Home
}]