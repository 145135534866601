import INIT from '../init.js'
import Vue from 'vue'
import _mixin from '../commons/_mixin'
import mixinAuth from './mixinAuth'
import mixinSheetsApi from './mixinSheetsApi'
import dayjs from 'dayjs'

const computed4store = {}
_.forEach(INIT.STORE_STATE, (init, key) => {
  computed4store[key] = {
    get() { return this.$store.state[key] },
    set(value) { this.$store.commit('set' + _.capitalize(key), value) }
  }
})

Vue.mixin({
  data: () => (Object.assign(_mixin.data, {
  })),
  computed: Object.assign(_mixin.computed, computed4store, {
    // 銘柄一覧
    brands() {
      let brands = _.compact(_.uniq(_.map(this.bottles, 'brand')))
      _.forEach(brands, (brand, i) => {
        brands[i] = {
          name: brand,
          label: brand, // for selection
          num: _.filter(this.bottles, ['brand', brand]).length
        }
      })
      return _.orderBy(brands, ['num', 'name'], ['desc', 'asc'])
    },
    // メモ一覧
    memos() {
      const memos = _.flatten(_.map(this.bottles, 'memos'))
      let memoUniqs = _.compact(_.uniq(memos))
      _.forEach(memoUniqs, (memo, i) => {
        memoUniqs[i] = {
          name: memo,
          label: memo, // for selection
          num: _.filter(memos, value => { return value === memo }).length
        }
      })
      return _.reverse(_.sortBy(memoUniqs, 'num'))
    },
  }),

  methods: Object.assign(_mixin.methods, mixinAuth.methods, mixinSheetsApi.methods, {

    // 新規作成
    async create(newSheets) {
      let isSuccess = false
      newSheets = newSheets || await this.getNewSheets()
      if (newSheets) {
        const res = await this.apiCreate(newSheets)
        if (_.has(res, 'data.spreadsheetId')) {
          this.appData = _.assign(this.appData, {spreadsheetId: res.data.spreadsheetId})
          isSuccess = true
        }
      }
      return isSuccess
    },
    // 新規データ取得
    async getNewSheets() {
      const res = await this.apiGetNewSheets()
      return _.has(res, 'data.sheets.length') ? res.data.sheets : null
    },
    // データ更新
    async saveRow(index, bottle) {
      const res = await this.apiUpdateRow(index, [
        _.map(INIT.DATA_STRUCTURE.current, key => {
          if (_.includes(['names', 'memos'], key)) { // array
            return _.join(bottle[key])
          } else if (_.includes(['createdAt', 'updatedAt'], key)) { // date
            return bottle[key] && _.isNumber(dayjs(bottle[key]).valueOf()) ? dayjs(bottle[key]).format('YYYY/MM/DD') : ''
          } else { // others
            return bottle[key]
          }
        })
      ])
      return _.get(res, 'status') === 200 ? true : false
    },
    // データ削除
    async deleteRow(index) {
      const res = await this.apiDeleteRow(index)
      return _.get(res, 'status') === 200 ? true : false
    },
    // 編集権限チェック
    async checkEditable() {
      const res = await this.apiCheckEditable()
      this.editable = _.get(res, 'response.data.error.code') === 400 ? true : false // 400 or 403 or
    },
    // データ更新
    async renewData() {
      let isSuccess = false
      if (this.appData.spreadsheetId) {
        const res = await this.apiGetData()
        if (_.has(res, 'data.values')) {
          const rawData = _.get(res, 'data.values')
          if (this.isNormal(rawData)) {
            this.firstRowIndex = this.getFirstRowIndex(rawData)
            this.bottles = this.convert(rawData)
            this.renewAppDataSheets(res)
            isSuccess = true
            console.log('renewed')
          } else {
            // データ形式エラー
            this.errorCode = 401
            await this.signOut()
          }
        } else if (res.message === 'Network Error') {
          // ネットワークエラー
          this.errorCode = 900
        } else if (res) {
          // 403:権限なし or 404:ID間違い
          this.errorCode = res.data.error.code || 999
          this.signOut()
        }
      }
      return isSuccess
    },
    // appData更新
    renewAppDataSheets(res) {
      const appData = _.cloneDeep(this.appData)
      appData.sheets = appData.sheets || [] // update 対策（アプリ移行後削除）
      const storeName = res.data.values[2][9] || '' // update 対策（アプリ移行後削除）
      const foundIndex = _.findIndex(this.appData.sheets, ['id', this.appData.spreadsheetId])
      if (foundIndex >= 0) {
        appData.sheets[foundIndex] = {id: this.appData.spreadsheetId, storeName: storeName, url: res.config.url}
      } else {
        appData.sheets.push({id: this.appData.spreadsheetId, storeName: storeName, url: res.config.url})
      }
      this.appData = appData
    },
    // データ変換
    convert(rawData) {
      const bottles = []
      _.forEach(rawData, (each, i) => {
        if (i >= this.firstRowIndex) {
          bottles.push({
            index: i + 1, // rowIndex
            id: each[0] ? _.toNumber(each[0]) : null,
            createdAt: (each[1] && dayjs(each[1]).valueOf() > 0) ? dayjs(each[1]).format('YYYY-MM-DD') : null,
            updatedAt: (each[2] && dayjs(each[2]).valueOf() > 0) ? dayjs(each[2]).format('YYYY-MM-DD') : null,
            brand: each[3] || null,
            num: each[4] ? _.toNumber(each[4]) : null,
            remain: each[5] ? _.toNumber(each[5]) : null,
            names: each[6] ? each[6].split(',') : [],
            memos: each[7] ? each[7].split(',') : [],
            isActive: false,
          })
        }
      })
      return bottles
    },
    // データ検証
    isNormal(rawData) {
      return (
        rawData.length
        && _.isEqual(INIT.DATA_STRUCTURE.veridate, _.take(rawData[0], 8))
      )
    },
    // 開始行番号取得
    getFirstRowIndex(rawData) {
      const index = _.findIndex(rawData, each => { return each[0] && ! _.isNaN(_.toNumber(each[0])) })
      return index >= 0 ? index : rawData.length
    },
  }),
})

// this.$gapi.clientProvider.client.gapi.client.drive.permissions.list({
//   'fileId': this.appData.spreadsheetId
// }).then((res) => { console.log(res) }).catch((err) => {console.log(err)})
// this.sheetsApi.spreadsheets.batchUpdate({
//   spreadsheetId: this.appData.spreadsheetId,
//   resource: {requests: [{
//     createDeveloperMetadata: {
//       developerMetadata: {
//         "location": {
//           "spreadsheet": true,
//         },
//         "metadataKey": 'accessedAt',
//         "metadataValue": _.toString(dayjs().valueOf()),
//         "visibility": "PROJECT",
//       }
//     }
//   }]}
// }).then((res) => { console.log(res) })
// this.sheetsApi.spreadsheets.get({
//   spreadsheetId: this.appData.spreadsheetId,
// }).then((res) => { console.log(res) })
