<template>
  <div
    class="item-list"
    v-show="! isSearching"
  >
    <v-list
      v-for="(bottlesByBrand, index0) in bottlesByBrands"
      :key="index0"
    >
      <v-subheader>{{ bottlesByBrand.name }}</v-subheader>
      <template
        v-for="(bottle, index1) in bottlesByBrand.bottles"
      >
        <v-lazy
          :key="index1"
          v-model="lazyActive[bottlesByBrand.name][index1]"
          min-height="70"
        >
        <v-list-item
          @click="bottleIndex = bottle.index"
        >
            <v-list-item-avatar
              class="num"
              v-if="bottle.num"
            >{{ bottle.num }}</v-list-item-avatar>
            <v-list-item-avatar
              class="remain"
              v-else
            >
              <span class="background" :style="{height: bottle.remain ? bottle.remain + '%' : 0}"></span>
              <span class="value">{{ bottle.remain }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div class="names">
                  <span
                    v-for="(name, index2) in bottle.names"
                    :key="index2"
                  >{{ name }}</span>
                </div>
                <div class="memos" v-if="bottle.memos">
                  <span
                    v-for="(memo, index3) in bottle.memos"
                    :key="index3"
                  >{{ memo }}</span>
                </div>
              </v-list-item-title>
              <v-list-item-subtitle v-if="bottle.updatedAt || bottle.createdAt">
                <div class="date">{{ bottle.updatedAt || '-' }} / {{ bottle.createdAt || '-' }}</div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-lazy>
      </template>
    </v-list>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        bottleList: [],
        lazyActive: {}, // v-lazy対応
      }
    },
    computed: {
      filteredBottles() {
        return _.filter(this.bottles, bottle => {
          return (
            (! this.keyword0 || (this.keyword0 === 'num' && ! _.isNull(bottle.num)) || (this.keyword0 === 'remain' && _.isNull(bottle.num)))
            && (! this.keyword1 || bottle.brand === this.keyword1)
            && (! this.keyword2 || (
              _.find(bottle.names, name => { return _.includes(name, this.keyword2) })
              || _.find(bottle.memos, memo => { return _.includes(memo, this.keyword2) }
            )))
          )
        })
      },
      bottlesByBrands() {
        const bottlesByBrands = []
        _.forEach(this.brands, brand => {
          bottlesByBrands.push({
            name: brand.name,
            bottles: _.sortBy(_.filter(this.filteredBottles, ['brand', brand.name]), 'num'),
          })
        })
        return _.filter(bottlesByBrands, bottlesByBrand => { return bottlesByBrand.bottles.length })
      },
    },
    mounted() {},
    watch: {
      // v-lazy対応
      bottlesByBrands: {
        handler() {
          this.lazyActive = {}
          _.forEach(this.bottlesByBrands, brand => {
            this.lazyActive[brand.name] = _.fill(Array(brand.bottles.length), false)
          })
        },
        immediate: true
      },

      // 検索後スクロールをtopに
      isSearching() {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 0)
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .item-list {
    padding-bottom: 60px;
  }
  .v-list {
    padding: 0 0 30px 0;
    .v-subheader {
      color: #FFF;
      position: sticky;
      top: 80px;
      z-index: 1;
      font-size: 1.4rem;
      background-color: var(--v-primaryBG-base);
      height: auto;
      display: block;
      // text-align: right;
      padding: 10px 20px;
    }
    .v-list-item {
      padding: 2px 16px;
      &:nth-child(even) {
        background-color: #222;
      }
    }
    .v-avatar {
      height: 50px !important;
      width: 50px !important;
      font-size: 1.6rem;
      background-color: #000;
      margin-right: 20px;
      // &.num {}
      &.remain {
        border-radius: 0;
        span {
          position: relative;
          &.background {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #455A64;
          }
        }
      }
    }
    .v-list-item__title {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.5rem;
      span {
        margin: 0 .5em 0 0;
      }
      .memos {
        span {
          font-size: 1rem;
          margin: 0 .5em 0 0;
          background-color: #111;
          color: #DDD;
          padding: 3px 10px;
          border-radius: 5px;
        }
      }
    }
    .v-list-item__subtitle {
      margin-top: 5px;
    }
  }
</style>
