<template>
  <v-card
    v-if="isSearching"
    tile
  >
    <v-row class="keyword0">
      <v-col
        v-for="bottleType in bottleTypes"
        :key="bottleType.name"
      >
        <v-btn
          rounded
          :disabled="keyword0 === bottleType.name"
          @click="submit('keyword0', bottleType.name)"
        >{{ bottleType.label[lang] }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list>
          <v-subheader>{{ $t('brand') }}</v-subheader>
          <v-list-item
            v-for="brand in this.brands"
            :key="brand.name"
          >
            <v-btn
              rounded
              :disabled="keyword1 === brand.name"
              @click="submit('keyword1', brand.name)"
            >{{ brand.label }}</v-btn>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col>
        <v-list>
          <v-subheader>{{ $t('note') }}</v-subheader>
          <v-list-item
            v-for="memo in _.take(this.memos, 20)"
            :key="memo.name"
          >
            <v-btn
              rounded
              @click="submit('keyword2', memo.name)"
            >{{ memo.label }}</v-btn>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        bottleTypes: [{
          label: {ja: '番号ボトル', en: 'Number'},
          name: 'num'
        }, {
          label: {ja: '残量ボトル', en: 'Remain'},
          name: 'remain'
        }],
      }
    },
    computed: {},
    mounted() {},
    watch: {},
    methods: {
      submit(type, word) {
        this[type] = word
        this.isSearching = false
      }
    },
    i18n: {
      messages: {
        ja: {
        },
        en: {
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .row {
    margin: 0;
  }
  .col {
    max-width: 50%;
    box-sizing: border-box;
    padding: 0;
  }
  .v-list-item {
    display: block;
    padding: 5px 16px;
  }
  .v-btn {
    display: block;
    white-space: normal;
    height: auto !important;
    min-height: 36px;
    font-size: 1.1rem;
    font-weight: normal;
  }
  .keyword0 {
    .col {
      padding: 12px 12px 0;
    }
    .v-btn {
      width: 100%;
    }
  }
</style>
