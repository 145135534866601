// common store
import INIT from '../init'

export default {
  state: {
    storage: {},
    pageId: 'home',
    drawer: false,
    tabId: 'tab0',
    isLoading: false,
    bodyWidth: window.innerWidth,
    bodyHeight: window.innerHeight,
    safeArea: {top: 0, bottom: 0, right: 0, left: 0,},
    appBarHeight: 56,
    scrollTop: 0,
    pullto: 'ready', // null, ready, pulling, loading, done
    isThemeDark: false, // isDarkだとvuetifyとコンフリクト
    urlParams: {},
  },
  mutations: {
    setStorage(state, data) {
      state.storage = data
      localStorage.setItem(INIT.APP_ID, JSON.stringify(data))
    },
    setPageId(state, id) {
      state.pageId = id
    },
    setDrawer(state, isOpen) {
      state.drawer = isOpen
    },
    setTabId(state, tabId) {
      state.tabId = tabId
    },
    setIsLoading(state, bool) {
      state.isLoading = bool;
    },
    renewSize(state) {
      state.bodyWidth = window.innerWidth
      state.bodyHeight = window.innerHeight
    },
    renewScrollTop(state) {
      state.scrollTop = window.pageYOffset
    },
    setSafeArea(state, obj) {
      state.safeArea = obj
    },
    setAppBarHeight(state, height) {
      state.appBarHeight = height
    },
    setPullto(state, status) {
      state.pullto = status
    },
    setIsThemeDark(state, bool) {
      state.isDark = bool
    },
    setUrlParams(state, obj) {
      state.urlParams = obj
    },
  },
}