<template>
  <v-dialog
    v-model="dialog"
    persistent
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text v-html="$t('error' + errorCode)"></v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="errorCode = null"
        >{{ $t('dialog.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  // signIn, getToken, refreshToken, renewData時にエラーハンドリング
  export default {
    computed: {
      dialog() {
        return this.errorCode ? true : false
      }
    },
    methods: {},
    watch: {},
    i18n: {
      messages: {
        ja: {
          // ログイン
          error100: 'ログアウト済みです。<br>再度ログインしてください。',
          error101: 'ログインに失敗しました。<br>再度ログインしてください。',
          error102: 'スプレッドシートにアクセスする権限がありません。<br>再度ログインし、スプレッドシートへのアクセスを許可してください。',
          // スプレッドシート
          error401: 'データの形式が正しくありません。<br>別のスプレッドシートIDを入力してください。',
          error403: '指定されたスプレッドシートへのアクセス権限がありません。<br>データのオーナーへ「ファイル共有」を申請してください。',
          error404: 'IDが正しくありません。<br>正しいスプレッドシートIDを設定して下さい。',
          error409: 'データの読み込みに失敗しました。',
          // ネットワーク
          error900: 'ネットワークエラーです。<br>時間をおいて再度ログインしてください。',
          // 不明
          error999: '不明なエラーです。。<br>時間をおいて再度ログインしてください。'
        },
        en: {
          error100: 'You have already logged out. <br>Please log in again.',
          error101: 'Login failed. <br> Please log in again.',
          error102: 'You do not have permission to access the spreadsheet. Please log in again and allow access to the spreadsheet.',
          error401: 'The data format is incorrect.<br>Please enter a different spreadsheet ID.',
          error403: 'You do not have access to the specified spreadsheet.<br>Please apply for "file sharing" to the data owner.',
          error404: 'ID is incorrect.<br>Set the correct spreadsheet ID',
          error409: 'Failed to read the data.',
          error900: 'Network error. Please log in again after a while.',
          error999: 'Unknown error. Please log in again after a while.'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  // .v-card__text {
  // }
</style>
