
import dayjs from 'dayjs'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import axios from 'axios'
import INIT from '../init.js'

export default {
  methods: {
    // サインイン
    async signIn() {
      let isSuccess = false
      const res = await GoogleAuth.signIn().catch((err) => { JSON.stringify(err); console.log(err) })
      console.log(res)
      console.log(location.origin)
      if (_.has(res, 'serverAuthCode')) {
        const resToken = await this.getToken(res.serverAuthCode)
        if (resToken) {
          this.user = {email: res.email, name: res.name}
          isSuccess = true
        }
      } else {
        // ログイン失敗
        this.errorCode = 101
      }
      // error時signout
      if (! isSuccess) { await this.signOut() }
      return isSuccess
    },
    // アクセス＆リフレッシュトークン取得（signin時）
    async getToken(serverAuthCode) {
      let response
      const res = await axios.post('https://www.googleapis.com/oauth2/v4/token', {
        client_id: INIT.API_KEYS.client_id,
        client_secret: INIT.API_KEYS.client_secret,
        code: serverAuthCode,
        grant_type: 'authorization_code',
        redirect_uri: location.origin, // android&ios 要変更
      }).catch(async (err) => { response = err })
      response = response || res
      if (_.has(response, 'data.scope')) {
        if (_.includes(response.data.scope, 'spreadsheets')) {
          if (_.has(response, 'data.access_token') && _.has(response, 'data.refresh_token')) {
            this.accessToken = res.data.access_token
            this.expireAt = dayjs().valueOf() + 3000
            this.appData = _.assign(this.appData, {refreshToken: res.data.refresh_token})
            return true
          } else {
            // 不明エラー
            this.errorCode = 999
            return false
          }
        } else {
          // 権限エラー
          this.errorCode = 102
          return false
        }
      } else if (_.get(response, 'message') === 'Network Error') {
        // ネットワークエラー
        this.errorCode = 900
        return false
      } else {
        // 不明エラー
        this.errorCode = 999
        return false
      }
    },
    // アクセストークン取得
    async getAccessToken() {
      if (this.accessToken && this.expireAt > dayjs().valueOf()) { // 有効
        return this.accessToken
      } else if (this.appData.refreshToken) {
        const res = await this.refreshToken()
        if (res) { // 再取得
          return this.accessToken
        } else { // 無効
          return null
        }
      } else { // 未ログイン
        return null
      }
    },
    // アクセストークン再取得
    async refreshToken() {
      let response
      const res = await axios.post('https://www.googleapis.com/oauth2/v4/token', {
        client_id: INIT.API_KEYS.client_id,
        client_secret: INIT.API_KEYS.client_secret,
        refresh_token: this.appData.refreshToken,
        grant_type: 'refresh_token',
        redirect_uri: location.origin, // android&ios 要変更
      }).catch((err) => { response = err })
      response = response || res
      if (_.has(response, 'data.access_token')) {
        this.accessToken = res.data.access_token
        this.expireAt = dayjs().valueOf() + 3000
        this.user = await this.getUserInfo()
        return true
      } else if (_.get(response, 'message') === 'Network Error') {
        // ネットワークエラー
        this.errorCode = 900
        return false
      } else {
        // 有効期限切れ
        this.errorCode = 100
        await this.signOut()
        return false
      }
    },
    // ユーザー情報取得（必ず取得できる前提）
    async getUserInfo() {
      const res = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
        params: {access_token: this.accessToken}
      }).catch((err) => { console.log(err) })
      if (_.has(res, 'data.email')) {
        return {email: res.data.email, name: res.data.name}
      } else {
        return null
      }
    },
    // サインアウト
    async signOut() {
      await GoogleAuth.signOut().catch((err) => { console.log(err) })
      this.bottles = null
      this.user = null
      this.accessToken = null
      this.expireAt = 0
      this.appData = _.assign(this.appData, {refreshToken: null})
      return true
    },
  }
}

