<template>
  <div></div>
</template>

<script>
  import INIT from '../../init.js'
  import { StatusBar } from '@capacitor/status-bar'
  import { App } from '@capacitor/app'
  import vuetify from '../../settings/vuetify'

  export default{
    created() {
      // statusbar
      this.setStatusBar()
      // resize
      window.addEventListener('resize', _.debounce(() => {
        this.$store.commit('renewSize')
        this.setSafeArea()
      }, 200))
      // scroll
      window.addEventListener('scroll', _.debounce(() => {
        this.$store.commit('renewScrollTop');
      }, 200))
      // storage
      let storage;
      if (localStorage.getItem(INIT.APP_ID)) {
        try {
          storage = JSON.parse(localStorage.getItem(INIT.APP_ID))
        } catch(err){
          storage = INIT.LOCAL_DATA_STRUCTURE
          console.log(err)
        }
      } else {
        storage = INIT.LOCAL_DATA_STRUCTURE
      }
      this.$store.commit('setStorage', storage)
      // title
      document.title = this.$t('appName')
      // url parameter
      this.urlParams = this.getParams(location.hash) // for browser
      App.addListener('appUrlOpen', data => {
        if (data && data.url) {
          this.urlParams = this.getParams(data.url) // for app
        }
      })
    },
    mounted() {
      // safe area for JS
      let looped = 0
      const interval = setInterval(() => {
        if (looped > 10) {
          clearInterval(interval)
        } else {
          this.setSafeArea()
        }
        looped++
      }, 200)
    },
    watch: {
      appData() {
        // vuetify theme
        vuetify.framework.theme.dark = (
          this.appData.theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches
          || this.appData.theme === 'dark'
        )
        // status bar
        this.setStatusBar()
      }
    },
    methods: {
      setSafeArea() {
        const computedStyle = getComputedStyle(document.documentElement)
        const safeArea = {
          top: _.toNumber(computedStyle.getPropertyValue("--sat").replace('px', '')),
          bottom: _.toNumber(computedStyle.getPropertyValue("--sab").replace('px', '')),
          left: _.toNumber(computedStyle.getPropertyValue("--sal").replace('px', '')),
          right: _.toNumber(computedStyle.getPropertyValue("--sar").replace('px', '')),
        }
        this.$store.commit('setSafeArea', safeArea)
        this.$store.commit('setAppBarHeight', 58 + safeArea.top)
      },
      getParams(param) {
        const urlParams = {}
        if (_.includes(param, '?')) {
          const params = param.split('?')[1].split('&')
          _.forEach(params, param => {
            urlParams[param.split('=')[0]] = param.split('=')[1]
          })
        }
        return urlParams
      },
      setStatusBar() {
        if (this.platform !== 'web') {
          StatusBar.setBackgroundColor({
            color: this.isThemeDark ? INIT.COLOR.dark.statusBar : INIT.COLOR.light.statusBar
          })
        }
      },
    }
  }
</script>

<style>
  /* safe area for JS */
  :root {
    --sat: env(safe-area-inset-top);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
    --sar: env(safe-area-inset-right);
  }
</style>
