// common i18n

export default {
  ja: {
    // 地図
    'map': {
      'locationFailed': '現在地を取得できませんでした',
      'searchFailed': 'は見つかりませんでした'
    },
    // ダイアログ
    'dialog': {
      'yes': 'OK',
      'no': 'キャンセル',
      'save': '保存',
      'delete': '削除',
      'close': '閉じる',
      'send': '送信する',
      'back': '戻る',
      'done': '完了',
      'checkNetwork': 'ネットワーク接続を確認してください',
      'retry': '再接続する'
    },
    // 出典
    'source': '出典：',
  },
  en: {
    // 地図
    'map': {
      'locationFailed': 'Failed to get your location',
      'searchFailed': ' was not found.'
    },
    // ダイアログ
    'dialog': {
      'yes': 'OK',
      'no': 'CANCEL',
      'save': 'SAVE',
      'delete': 'DELETE',
      'close': 'CLOSE',
      'send': 'SEND',
      'back': 'BACK',
      'done': 'DONE',
      'checkNetwork': 'Check your network connection.',
      'retry': 'RETRY'
    },
    // 出典
    'source': 'source:',
  },
}
