import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _i18n from '../commons/_i18n'
import _mixin from '../commons/_mixin'

Vue.use(VueI18n)

export default new VueI18n({
  locale: _mixin.computed.lang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {
    ja: Object.assign(_i18n.ja, {
      appName: 'Bottles',
      appTitle: 'Bottles | ボトルキープ管理アプリ「ボトルズ」',
      appDesc: 'クラブやバーなどの飲食店でボトルキープを管理するアプリです',
      brand: '銘柄',
      Number: '番号',
      name: '名前',
      note: 'メモ',
    }),
    en: Object.assign(_i18n.en, {
      appName: 'Bottles',
      appTitle: 'Bottles | "Bottle keeping" management app',
      appDesc: 'An app that manages "bottle keeping" at restaurants such as clubs and bars',
      brand: 'Brand',
      number: 'Number',
      name: 'Name',
      note: 'Note',
    }),
  }
})
